export const SAVE_INTRODUCTION = 'saveIntroduction'
export const SAVE_SITE_INFO = 'saveSiteInfo'
export const SAVE_COMMENT_RESULT = 'saveCommentResult'
export const SET_COMMENT_QUERY_PAGE = 'setCommentQueryPage'
export const SET_COMMENT_QUERY_ARTICLE_ID = 'setCommentQueryArticleId'
export const SET_COMMENT_QUERY_PAGE_NUM = 'setCommentQueryPageNum'
export const SET_PARENT_COMMENT_ID = 'setParentCommentId'
export const RESET_COMMENT_FORM = 'resetCommentForm'
export const RESTORE_COMMENT_FORM = 'restoreCommentForm'
export const SET_IS_ARTICLE_RENDER_COMPLETE = 'setIsArticleRenderComplete'
export const SET_ARTICLE_PASSWORD_DIALOG_VISIBLE = 'setArticlePasswordDialogVisible'
export const SET_ARTICLE_PASSWORD_FORM = 'setArticlePasswordForm'
export const SET_FOCUS_MODE = 'setFocusMode'
export const SET_IS_ARTICLE_TO_HOME = 'setIsArticleToHome'
export const SAVE_CLIENT_SIZE = 'saveClientSize'